<template>
	<nav id="top-nav">
		<div class="navbar navbar-expand bg-clear navbar-light py-4">
			<div class="container-fluid">
				<router-link to="/" class="logo-link flex-shrink-0">
					<img v-if="theme === 'sgb'" class="navbar-brand" src="@/assets/images/logo-sgb.svg" width="65" alt="MySGB Logo">
					<img v-else-if="theme === 'aluma'" class="navbar-brand" src="@/assets/images/logo-aluma.svg" width="150" alt="MyAlumaPortal Logo">
					<img v-else-if="theme === 'sgbaluma'" class="navbar-brand" src="@/assets/images/logo-sgb-aluma.svg" width="150" alt="MySGBAlumaPortal Logo">
					<img v-else-if="theme === 'beis'" class="navbar-brand" src="@/assets/images/logo-beis.svg" width="125" alt="MyBeisPortal Logo">
					<img v-else class="navbar-brand" src="@/assets/images/logo-huennebeck.svg" width="175" alt="Hünnebeck Logo">
				</router-link>
				<h2 v-if="projectsStore.selected" class="fw-bold fs-5 d-none d-md-block my-0 me-4 flex-grow-0 flex-shrink-1" style="color: #778089;white-space: nowrap; word-break: keep-all; overflow: hidden; text-overflow: ellipsis;">
					{{ $t('legacy.project') }}: <span :title="projectsStore.selected?.address">{{ projectsStore.selected?.address || $t('noSelection') }}</span>
					<span v-if="projectsStore.selected?.archived" class="badge text-bg-secondary ms-2 small">{{ $t('archived') }}</span>
				</h2>
				<div v-if="auth.isLoggedIn()" class="collapse navbar-collapse" id="top-nav-bar">
					<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
						<li class="nav-item dropdown">
							<router-link to="/auth" class="nav-link dropdown-toggle fw-bold" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="bi bi-globe-americas me-1"></i>
								<span class="d-none d-sm-inline">{{ $i18n.locale.toUpperCase() }}</span>
							</router-link>
							<ul class="dropdown-menu">
								<li v-for="availableLanguage in sortedAvailableLanguages" :key="availableLanguage.key">
									<a class="dropdown-item" href="#" @click.prevent="setLanguage(availableLanguage.key)" :class="{ active: $i18n.locale === availableLanguage.key }">
										{{ availableLanguage.display }}
									</a>
								</li>
							</ul>
						</li>
						<li class="nav-item dropdown user-nav-item">
							<a class="nav-link dropdown-toggle fw-semibold" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="bi bi-person-circle me-1"></i>
								<span class="d-none d-sm-inline">
									{{ auth.user ? `${auth.user.firstName} ${auth.user.lastName}` : 'User' }}
								</span>
							</a>
							<ul class="dropdown-menu">
								<li>
									<router-link to="/auth/profile" class="dropdown-item" active-class="active">
										<i class="bi bi-person-circle me-1"></i>
										{{ $t('auth.profile.title') }}
									</router-link>
								</li>
								<li v-if="!auth.hasPermission('admin.tickets.view')">
									<router-link to="/tickets" class="dropdown-item" active-class="active">
										<i class="bi bi-send-check-fill me-1"></i>
										{{ $t('tickets.myTickets') }}
									</router-link>
								</li>
								<li><hr class="dropdown-divider"></li>
								<li>
									<router-link to="/auth/logout" class="dropdown-item">
										<i class="bi bi-box-arrow-right me-1"></i>
										{{ $t('nav.signOut') }}
									</router-link>
								</li>
							</ul>
						</li>
						<li class="nav-item">
							<router-link :to="projectsStore.selected ? `/projects/${projectsStore.selected.id}/tickets/new` : '/tickets/new'" class="btn btn-primary">
								<i class="bi bi-chat-fill me-1"></i>
								<span class="d-none d-sm-inline">
									{{ $t('nav.contact') }}
								</span>
							</router-link>
						</li>
<!--						<li v-if="$i18n.locale !== 'pl'" class="nav-item dropdown tickets-nav-item">
							<a class="btn btn-primary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="bi bi-chat-fill me-1"></i>
								<span class="d-none d-sm-inline">
									{{ $t('nav.contact') }}
								</span>
							</a>
							<ul class="dropdown-menu dropdown-menu-end">
								<li v-for="inquiryType in ticketInquiryTypes" :key="inquiryType.name">
									<router-link :to="`${projectsStore.selected ? `/projects/${projectsStore.selected.id}` : ''}/tickets/new/${inquiryType.name}`" class="dropdown-item" active-class="active">
										{{ $t(inquiryType.display) }}
									</router-link>
								</li>
							</ul>
						</li>-->
						<li class="nav-item d-block d-sm-none">
							<button class="btn btn-light" type="button" aria-expanded="false" aria-label="Toggle navigation" @click="toggleMobileSidebar()">
								<span class="navbar-toggler-icon"></span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</nav>
	<div v-if="projectsStore.selected" class="d-block d-md-none container-fluid">
		<h2 class="fw-bold fs-5" style="color: #778089; white-space: nowrap; word-break: keep-all; overflow: hidden; text-overflow: ellipsis;">
			{{ $t('legacy.project') }}: {{ projectsStore.selected?.address || $t('noSelection') }}
			<span v-if="projectsStore.selected?.archived">({{ $t('archived') }})</span>
		</h2>
	</div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useEventBus } from '@/stores/bus'
import { useAuthStore } from '@/stores/auth'
import { useAuthService } from '@/services/AuthService'
import { useI18n } from 'vue-i18n'
import { useProjectsStore } from '@/stores/projects'
import { useSettingsStore } from '@/stores/settings'
import { useAnalytics } from '@/services/AnalyticsService'

const theme = import.meta.env.VITE_THEME || 'hbk'
const i18n = useI18n()
const { bus } = useEventBus()
const auth = useAuthStore()

const projectsStore = useProjectsStore()
const settings = useSettingsStore()

defineProps({
	title: {
		type: String,
		default: null,
	},
})

const availableLanguages = i18n.availableLocales || [
	'en',
	'de',
]

const sortedAvailableLanguages = computed(() => {
	return availableLanguages
		.map((lang) => ({ key: lang, display: i18n.t(`languages.${lang}`)}))
		.sort((a, b) => a.display.localeCompare(b.display))
})

const titleKey = ref<String|null>(null)

const ticketInquiryTypes = [
	{
		'name': 'cq',
		'display': 'legacy.ticketsPageUserSubmitFormFieldInqueryTypeCommercialQuestions',
	},
	{
		'name': 'tq',
		'display': 'legacy.ticketsPageUserSubmitFormFieldInqueryTypeTechnicalQuestions',
	},
	{
		'name': 'lq',
		'display': 'legacy.ticketsPageUserSubmitFormFieldInqueryTypeLogisticalQuestions',
	},
	{
		'name': 'ard',
		'display': 'legacy.ticketsPageUserSubmitFormFieldInqueryTypeAnnouncementOfReturnDelivery',
	},
	{
		'name': 'no',
		'display': 'legacy.ticketsPageUserSubmitFormFieldInqueryTypeNewOrder',
	},
	{
		'name': 'co',
		'display': 'legacy.ticketsPageUserSubmitFormFieldInqueryTypeComplaint',
	},
	{
		'name': 'gem',
		'display': 'legacy.ticketsPageUserSubmitFormFieldInqueryTypeGeneralEnquiriesMisc',
	},
]

const setLanguage = async (language: string) => {
	i18n.locale.value = language
	settings.setLanguage(language)
	useAnalytics().track('Language changed', { language })

	try {
		await useAuthService().updateLanguage(language)
		auth.user!.language = language
		auth.persist()
	} catch (e) {
		useEventBus().alert('Error saving language selection')
	}
}

const toggleMobileSidebar = () => {
	useEventBus().emit('toggleSidebar')
}

const updateTitle = () => {
	const route = useRoute()

	if (route) {
		titleKey.value = (route.meta?.topNavKey as String|null|undefined) || null
	}
}

watch(() => bus.value.get('setTopNavTitle'), (body) => {
	titleKey.value = body[0]
})

onMounted(updateTitle)
watch(() => useRoute(), updateTitle)
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';

header {
	background-color: #f1f1f1;
	//padding: 2rem 1.5rem;
}

#top-nav .navbar.bg-primary {
	background-color: transparent !important;//$navbar-color !important;
}

nav {
	// background-color: $navbar-color;
	//color: var(--bs-btn-color);
	//color: #fff;

	h1 {
		font-size: 1.25rem;
		margin: 0;
		padding: 0;
	}

	.nav-link {
		color: $gray;
		// color: $primaryTextColor;
	}
}

.logo-link {
	width: 300px;
	text-align: center;
	min-width: 250px;

	@media (max-width: 1200px) {
		width: calc(25% + 0.5rem);
	}

	@media (max-width: 576px) {
		width: 35%;
		max-width: 175px;
		min-width: 0;

		img {
			width: 100%;
		}
	}
}
</style>
